import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IVideoPlayerActiveState {
    activeVideoId: string
}

const initialState: IVideoPlayerActiveState = {
    activeVideoId: '',
};


const slice = createSlice({
    name: 'videoPlayerActive',
    initialState,
    reducers: {
        setVideoPlayerActive: (
            state,
            action: PayloadAction<IVideoPlayerActiveState>
        ) => {
            state.activeVideoId = action.payload.activeVideoId;
        },
    },
});

export const VideoPlayerActiveReducer = slice.reducer;
export const VideoPlayerActiveActions = slice.actions;
