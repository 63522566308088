import { EReportReason } from 'app/module/profile/domain/valueObject/ReportReason';

export class Report {

    public constructor(
        private _profileIdToReport: string,
        private _reason: EReportReason,
        private _description: string,
        private _email: string | null,
        private _fullName: string | null,
    ) {
        this._ensureIsValid();
    }

    get profileIdToReport(): string {
        return this._profileIdToReport;
    }

    get reason(): EReportReason {
        return this._reason;
    }

    get description(): string {
        return this._description;
    }

    get fullName(): string | null {
        return this._fullName;
    }

    get email(): string | null {
        return this._email;
    }

    private _ensureIsValid(): void {
        if (this._reason === EReportReason.OTHER) {
            if (!this._fullName || !this._email) {
                throw new Error('Report full name and email are required');
            }
        }
    }

}
