import React from 'react';
import { useTranslation } from 'react-i18next';
import { EPostTypeValue } from 'app/module/profile/domain/valueObject/PostType';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { POST_TYPE_IMAGE,POST_TYPE_VIDEO } from 'translations/tr';
import classnames from 'classnames';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { CameraIcon,VideoIcon } from 'assets/icon/sexon/svg/icons';

interface IPostTypeFormFieldProps {
    watchType: EPostTypeValue
    onClick: (value: EPostTypeValue) => void
}

export const PostTypeFormField = (props: IPostTypeFormFieldProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="component-post-type-form-field">
            <SexonButton
                type="button"
                title={(
                    <>
                        <CameraIcon />
                        <span>
                            {t(POST_TYPE_IMAGE)}
                        </span>
                    </>
                )}
                styling={ESexonButtonStyle.FILLED}
                className={classnames('post-type-form-field-button',{
                    selected: props.watchType === EPostTypeValue.IMAGE,
                })}
                onClick={()=> {
                    props.onClick(EPostTypeValue.IMAGE);
                }}
            />
            <SexonButton
                type="button"
                title={(
                    <>
                        <VideoIcon />
                        <span>
                            {t(POST_TYPE_VIDEO)}
                        </span>
                    </>
                )}
                styling={ESexonButtonStyle.FILLED}
                className={classnames('post-type-form-field-button',{
                    selected: props.watchType === EPostTypeValue.VIDEO,
                })}
                onClick={()=> {
                    props.onClick(EPostTypeValue.VIDEO);
                }}
            />
        </div>
    );
};
