import { useTranslation } from 'react-i18next';
import { SexonModal } from 'app/common/presentation/component/modal/SexonModal';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import './CreatePostModal.scss';
import { NEW_POST,PUBLISH } from 'translations/tr';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { useShowCreatePostModalReduxState } from 'app/common/presentation/redux/createPostModal/hook/useShowCreatePostModalReduxState';
import { CreatePostForm } from 'app/common/presentation/component/popUpManager/createPostModal/form/CreatePostForm';
import React from 'react';
import { useCreatePostModalController } from 'app/common/presentation/component/popUpManager/createPostModal/useCreatePostModalController';
import { EProfileImageType,ProfileImage } from 'app/module/profile/presentation/component/profileImage/ProfileImage';
import { useCurrentProfile } from 'app/common/presentation/hook/useCurrentProfile';
import { UploadingVideoSpinner } from 'app/common/presentation/component/popUpManager/createPostModal/uploadingVideoSpinner/UploadingVideoSpinner';

export const CreatePostModal = (): JSX.Element => {
    const { t } = useTranslation();
    const showModal = useShowCreatePostModalReduxState();
    const currentProfile = useCurrentProfile();
    const UseCreatePostModalController = useCreatePostModalController();

    return (
        <>
            {UseCreatePostModalController.isUploadingVideo ? (
                <UploadingVideoSpinner
                    percentage={UseCreatePostModalController.percentageUploaded}
                />
            ) : null}
            <SexonModal
                show={ showModal }
                headerTitle={ t(NEW_POST) }
                classname="component-create-post-modal"
                onClose={ UseCreatePostModalController.closeModal }
                headerIcon={
                    <ProfileImage
                        type={ EProfileImageType.SMALL }
                        image={ currentProfile.currentProfile.imageSmall }
                    />
                }
                body={ (
                    <div className="create-post-modal-body-container">
                        <CreatePostForm
                            createPostUseForm={UseCreatePostModalController.createPostUseForm}
                        />
                    </div>
                ) }
                footer={(
                    <div className="create-post-modal-footer-container">
                        <SexonButton
                            type="submit"
                            title={ t(PUBLISH).toUpperCase()}
                            styling={ESexonButtonStyle.FILLED}
                            className="create-post-modal-footer-button"
                            onClick={UseCreatePostModalController.submitCreatePostForm}
                        />
                    </div>
                )}
            />
        </>
    );
};
