import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    value: false
};

const slice = createSlice({
    name: 'fullScreenActive',
    initialState,
    reducers: {
        setFullScreenActive: (state,action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const FullScreenActiveReducer = slice.reducer;
export const FullScreenActiveActions = slice.actions;
