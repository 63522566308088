import './LoadingImageSkeleton.scss';
import React from 'react';
import { ESexonLazyImageType } from 'app/common/presentation/component/lazyImage/SexonLazyImage';

interface IImageSkeletonProps {
    title: string
    type: ESexonLazyImageType
}

export const LoadingImageSkeleton = (props: IImageSkeletonProps): JSX.Element => {
    return (
        <div className={
            props.type === ESexonLazyImageType.ROUND ?
                'component-loading-image-skeleton round' :
                'component-loading-image-skeleton'
        }>
            <p className="loading-image-skeleton-text">
                {props.title}
            </p>
        </div>
    );
};

