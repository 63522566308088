import { EPaymentMethod,IPaymentMethod } from 'app/module/profile/domain/valueObject/PaymentMethod';
import { EApiPaymentMethod } from 'app/common/data/remote/sexonApi/dto/ApiPaymentMethod';

export class PaymentMethodDataMapper {

    public static fromDomain(method: IPaymentMethod): EApiPaymentMethod {
        switch( method.value ){
            case EPaymentMethod.CASH:
                return EApiPaymentMethod.CASH;
            case EPaymentMethod.CARD:
                return EApiPaymentMethod.CARD;
            case EPaymentMethod.BIZUM:
                return EApiPaymentMethod.BIZUM;
            case EPaymentMethod.CRYPTOCURRENCIES:
                return EApiPaymentMethod.CRYPTOCURRENCIES;
            case EPaymentMethod.REVOLUT:
                return EApiPaymentMethod.REVOLUT;
            case EPaymentMethod.PAYPAL:
                return EApiPaymentMethod.PAYPAL;
            default:
                throw new Error('Invalid payment method');
        }
    }

    public static arrayFromDomain(methods: IPaymentMethod[]): EApiPaymentMethod[] {
        const methodsResponse: EApiPaymentMethod[] = [];

        methods.forEach((method) => {
            methodsResponse.push(this.fromDomain(method));
        });

        return methodsResponse;
    }

    public static fromResponse(method: EApiPaymentMethod): IPaymentMethod {
        switch( method ){
            case EApiPaymentMethod.CASH:
                return {
                    value: EPaymentMethod.CASH
                };
            case EApiPaymentMethod.BIZUM:
                return {
                    value: EPaymentMethod.BIZUM
                };
            case EApiPaymentMethod.CARD:
                return {
                    value: EPaymentMethod.CARD
                };
            case EApiPaymentMethod.CRYPTOCURRENCIES:
                return {
                    value: EPaymentMethod.CRYPTOCURRENCIES
                };
            case EApiPaymentMethod.REVOLUT:
                return {
                    value: EPaymentMethod.REVOLUT
                };
            case EApiPaymentMethod.PAYPAL:
                return {
                    value: EPaymentMethod.PAYPAL
                };
            default:
                throw new Error('Invalid api payment method');
        }
    }

    public static arrayFromResponse(methodsResponse: EApiPaymentMethod[]): IPaymentMethod[] {
        const methods: IPaymentMethod[] = [];

        methodsResponse.forEach((method) => {
            methods.push(this.fromResponse(method));
        });

        return methods;
    }
}
