import React,{ ChangeEvent,ReactElement,useEffect,useRef,useState } from 'react';
import { SELECT_ANOTHER_VIDEO,VIDEO_DURATION_ERROR,VIDEO_RANGE } from 'translations/tr';
import { CalendarIcon } from 'assets/icon/sexon/svg/icons';
import {
    ESexonSliderPosition,
    SexonSliderComponent
} from 'app/common/presentation/component/slider/SexonSliderComponent';
import { useTranslation } from 'react-i18next';
import './VideoUploader.scss';
import { PostSkeleton } from 'app/module/profile/presentation/component/postSkeleton/PostSkeleton';
import { EPostTypeValue,PostType } from 'app/module/profile/domain/valueObject/PostType';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { VideoUploaderPreview } from 'app/module/profile/presentation/component/videoUploader/preview/VideoUploaderPreview';
import setToastErrorUseCase from 'app/common/domain/useCase/SetToastErrorUseCase';

interface IVideoUploaderProps {
    onChange: (videoData: File, start: number, end: number, duration: number, size: number) => void
}

export const VideoUploader = (props: IVideoUploaderProps): ReactElement => {
    const { t } = useTranslation();
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileData, setFileData] = useState<File | null>(null);
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);
    const [endTime, setEndTime] = useState<number>(0);
    const [startTime, setStartTime] = useState<number>(0);
    const [totalDuration, setTotalDuration] = useState<number>(0);
    const [size, setSize] = useState<number>(0);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const file = e.target.files?.[0];
        if (file) {
            const videoURL = URL.createObjectURL(file);
            setVideoSrc(videoURL);
            setFileType(file.type);
            setSize(file.size);
            setFileData(file);
        }
    };

    const handleOnChangeSlider = (values: number | number[]): void => {
        if(Array.isArray(values)){
            setStartTime(values[0]);
            setEndTime(values[1]);
        }
    };

    const convertSecondsToTime = (seconds: number): string => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const cleanVideo = (): void => {
        setVideoSrc(null);
        setFileData(null);
        setFileType(null);
        setStartTime(0);
        setEndTime(0);
        setTotalDuration(0);
        setSize(0);
    };

    const openInput = (): void => {
        fileInputRef.current?.click();
        cleanVideo();
    };

    const handleOnPlayerReady = (duration: number): void => {
        setEndTime(Math.floor(duration));
        setTotalDuration(Math.floor(duration));
    };

    const handleLimitDuration = (): void => {
        cleanVideo();
        setToastErrorUseCase(t(VIDEO_DURATION_ERROR));
    };

    useEffect(() => {
        if(fileData){
            props.onChange(fileData, startTime, endTime, totalDuration, size);
        }
    },[videoSrc, startTime, endTime, totalDuration, size]);


    return (
        <div className="component-video-uploader">
            <input
                type="file"
                accept="video/*"
                ref={ fileInputRef }
                className="video-input"
                onChange={ handleFileChange }
            />
            {
                videoSrc && fileType ? (
                    <>
                        <VideoUploaderPreview
                            endTime={endTime}
                            videoSrc={videoSrc}
                            fileType={fileType}
                            startTime={startTime}
                            onReady={handleOnPlayerReady}
                            onExceedDuration={handleLimitDuration}
                        />

                        <div className="video-slider-container">
                            <SexonSliderComponent
                                range
                                step={ 1 }
                                min={ 0 }
                                max={ totalDuration }
                                allowCross={ false }
                                title={ t(VIDEO_RANGE) }
                                icon={ <CalendarIcon/> }
                                values={ [ startTime,endTime ] }
                                onChange={ handleOnChangeSlider }
                                position={ESexonSliderPosition.ABOVE}
                                maxTitle={ convertSecondsToTime(endTime) }
                                minTitle={ convertSecondsToTime(startTime) }
                            />

                            <div className="new-video-button-container">
                                <SexonButton
                                    type="button"
                                    onClick={ openInput }
                                    styling={ ESexonButtonStyle.FILLED }
                                    title={ t(SELECT_ANOTHER_VIDEO).toString() }
                                />
                            </div>
                        </div>
                    </>
                ) : (
                    <PostSkeleton
                        onClick={ openInput }
                        type={ new PostType(EPostTypeValue.VIDEO) }
                    />
                )
            }
        </div>
    );
};
