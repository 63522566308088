import { ReactComponent as Play } from 'assets/icon/sexon/svg/play.svg';
import { ReactComponent as City } from 'assets/icon/sexon/svg/city.svg';
import { ReactComponent as Home } from 'assets/icon/sexon/svg/home.svg';
import { ReactComponent as Zoom } from 'assets/icon/sexon/svg/zoom.svg';
import { ReactComponent as Logo } from 'assets/icon/sexon/svg/logo.svg';
import { ReactComponent as Time } from 'assets/icon/sexon/svg/time.svg';
import { ReactComponent as Blog } from 'assets/icon/sexon/svg/blog.svg';
import { ReactComponent as Edit } from 'assets/icon/sexon/svg/edit.svg';
import { ReactComponent as Cash } from 'assets/icon/sexon/svg/cash.svg';
import { ReactComponent as Info } from 'assets/icon/sexon/svg/info.svg';
import { ReactComponent as Help } from 'assets/icon/sexon/svg/help.svg';
import { ReactComponent as Price } from 'assets/icon/sexon/svg/euro.svg';
import { ReactComponent as Close } from 'assets/icon/sexon/svg/close.svg';
import { ReactComponent as World } from 'assets/icon/sexon/svg/world.svg';
import { ReactComponent as Share } from 'assets/icon/sexon/svg/share.svg';
import { ReactComponent as Email } from 'assets/icon/sexon/svg/email.svg';
import { ReactComponent as Login } from 'assets/icon/sexon/svg/login.svg';
import { ReactComponent as Bizum } from 'assets/icon/sexon/svg/bizum.svg';
import { ReactComponent as Video } from 'assets/icon/sexon/svg/video.svg';
import { ReactComponent as Search } from 'assets/icon/sexon/svg/search.svg';
import { ReactComponent as Logout } from 'assets/icon/sexon/svg/logout.svg';
import { ReactComponent as Gender } from 'assets/icon/sexon/svg/gender.svg';
import { ReactComponent as Height } from 'assets/icon/sexon/svg/height.svg';
import { ReactComponent as Delete } from 'assets/icon/sexon/svg/delete.svg';
import { ReactComponent as Paypal } from 'assets/icon/sexon/svg/paypal.svg';
import { ReactComponent as Camera } from 'assets/icon/sexon/svg/camera.svg';
import { ReactComponent as Revolut } from 'assets/icon/sexon/svg/revolut.svg';
import { ReactComponent as Aspect2 } from 'assets/icon/sexon/svg/aspect2.svg';
import { ReactComponent as Aspect1 } from 'assets/icon/sexon/svg/aspect1.svg';
import { ReactComponent as Aspect3 } from 'assets/icon/sexon/svg/aspect3.svg';
import { ReactComponent as Warning } from 'assets/icon/sexon/svg/warning.svg';
import { ReactComponent as Twitter } from 'assets/icon/sexon/svg/twitter.svg';
import { ReactComponent as Profile } from 'assets/icon/sexon/svg/profile.svg';
import { ReactComponent as Cookies } from 'assets/icon/sexon/svg/cookies.svg';
import { ReactComponent as LogoSimple } from 'assets/icon/sexon/svg/simple.svg';
import { ReactComponent as MoreMenu } from 'assets/icon/sexon/svg/moreMenu.svg';
import { ReactComponent as Province } from 'assets/icon/sexon/svg/province.svg';
import { ReactComponent as Settings } from 'assets/icon/sexon/svg/settings.svg';
import { ReactComponent as Register } from 'assets/icon/sexon/svg/register.svg';
import { ReactComponent as Calendar } from 'assets/icon/sexon/svg/calendar.svg';
import { ReactComponent as Services } from 'assets/icon/sexon/svg/services.svg';
import { ReactComponent as Rotation } from 'assets/icon/sexon/svg/rotation.svg';
import { ReactComponent as Category } from 'assets/icon/sexon/svg/category.svg';
import { ReactComponent as VolumeOn } from 'assets/icon/sexon/svg/volumeOn.svg';
import { ReactComponent as VolumeOff } from 'assets/icon/sexon/svg/volumeOff.svg';
import { ReactComponent as EditEmail } from 'assets/icon/sexon/svg/editEmail.svg';
import { ReactComponent as Instagram } from 'assets/icon/sexon/svg/instagram.svg';
import { ReactComponent as ArrowLeft } from 'assets/icon/sexon/svg/arrowLeft.svg';
import { ReactComponent as Filter } from 'assets/icon/sexon/svg/filterSearch.svg';
import { ReactComponent as PostPhone } from 'assets/icon/sexon/svg/postPhone.svg';
import { ReactComponent as HairColor } from 'assets/icon/sexon/svg/hairColor.svg';
import { ReactComponent as EditPhone } from 'assets/icon/sexon/svg/editPhone.svg';
import { ReactComponent as CreditCard } from 'assets/icon/sexon/svg/creditCard.svg';
import { ReactComponent as ArrowRight } from 'assets/icon/sexon/svg/arrowRight.svg';
import { ReactComponent as UploadPost } from 'assets/icon/sexon/svg/uploadPost.svg';
import { ReactComponent as SmartPhone } from 'assets/icon/sexon/svg/smartPhone.svg';
import { ReactComponent as Successful } from 'assets/icon/sexon/svg/successful.svg';
import { ReactComponent as Description } from 'assets/icon/sexon/svg/description.svg';
import { ReactComponent as ArrowDropUp } from 'assets/icon/sexon/svg/arrowDropUp.svg';
import { ReactComponent as ContactsFilter } from 'assets/icon/sexon/svg/contacts.svg';
import { ReactComponent as ProfileName } from 'assets/icon/sexon/svg/profileName.svg';
import { ReactComponent as ProfileGrid } from 'assets/icon/sexon/svg/profileGrid.svg';
import { ReactComponent as Suggestions } from 'assets/icon/sexon/svg/suggestions.svg';
import { ReactComponent as HideProfile } from 'assets/icon/sexon/svg/hideProfile.svg';
import { ReactComponent as ProfileFeed } from 'assets/icon/sexon/svg/profileFeed.svg';
import { ReactComponent as ExternalLink } from 'assets/icon/sexon/svg/externalLink.svg';
import { ReactComponent as ShowPassword } from 'assets/icon/sexon/svg/showPassword.svg';
import { ReactComponent as HidePassword } from 'assets/icon/sexon/svg/hidePassword.svg';
import { ReactComponent as HighlightProfile } from 'assets/icon/sexon/svg/highlight.svg';
import { ReactComponent as ArrowDropDown } from 'assets/icon/sexon/svg/arrowDropDown.svg';
import { ReactComponent as EnableProfile } from 'assets/icon/sexon/svg/enableProfile.svg';
import { ReactComponent as DeleteAccount } from 'assets/icon/sexon/svg/deleteAccount.svg';
import { ReactComponent as NaughtyPhrase } from 'assets/icon/sexon/svg/naughtyPhrase.svg';
import { ReactComponent as HamburgerMenu } from 'assets/icon/sexon/svg/hamburgerMenu.svg';
import { ReactComponent as AspectOriginal } from 'assets/icon/sexon/svg/aspectOriginal.svg';
import { ReactComponent as TelegramFilled } from 'assets/icon/sexon/svg/telegramFilled.svg';
import { ReactComponent as CryptoCurrency } from 'assets/icon/sexon/svg/cryptoCurrency.svg';
import { ReactComponent as PaymentMethods } from 'assets/icon/sexon/svg/paymentMethods.svg';
import { ReactComponent as ForgotPassword } from 'assets/icon/sexon/svg/forgotPassword.svg';
import { ReactComponent as WhatsappFilled } from 'assets/icon/sexon/svg/whatsappFilled.svg';
import { ReactComponent as TelegramOutline } from 'assets/icon/sexon/svg/telegramOutline.svg';
import { ReactComponent as WhatsappOutline } from 'assets/icon/sexon/svg/whatsappOutline.svg';
import { ReactComponent as FilterActive } from 'assets/icon/sexon/svg/filterSearchActive.svg';
import { ReactComponent as AddExternalLink } from 'assets/icon/sexon/svg/addExternalLink.svg';
import { ReactComponent as ProfileUserName } from 'assets/icon/sexon/svg/profileUserName.svg';
import { ReactComponent as DetailProfileCard } from 'assets/icon/sexon/svg/detailProfileCard.svg';
import { ReactComponent as SexualOrientation } from 'assets/icon/sexon/svg/sexualOrientation.svg';
import { ReactComponent as HighlightedProfile } from 'assets/icon/sexon/svg/highlightedProfile.svg';
import { ReactComponent as EditBasicInformation } from 'assets/icon/sexon/svg/editBasicInformation.svg';


export const PlayIcon = Play;
export const HelpIcon = Help;
export const ZoomIcon = Zoom;
export const CashIcon = Cash;
export const HomeIcon = Home;
export const InfoIcon = Info;
export const LogoIcon = Logo;
export const TimeIcon = Time;
export const EditIcon = Edit;
export const CityIcon = City;
export const BlogIcon = Blog;
export const BizumIcon = Bizum;
export const VideoIcon = Video;
export const CloseIcon = Close;
export const WorldIcon = World;
export const PriceIcon = Price;
export const LoginIcon = Login;
export const EmailIcon = Email;
export const SearchIcon = Search;
export const CameraIcon = Camera;
export const FilterIcon = Filter;
export const LogoutIcon = Logout;
export const GenderIcon = Gender;
export const HeightIcon = Height;
export const DeleteIcon = Delete;
export const PaypalIcon = Paypal;
export const CookiesIcon = Cookies;
export const Aspect1Icon = Aspect1;
export const Aspect2Icon = Aspect2;
export const Aspect3Icon = Aspect3;
export const TwitterIcon = Twitter;
export const RevolutIcon = Revolut;
export const SharePostIcon = Share;
export const WarningIcon = Warning;
export const ProfileIcon = Profile;
export const VolumeOnIcon = VolumeOn;
export const CategoryIcon = Category;
export const MoreMenuIcon = MoreMenu;
export const ProvinceIcon = Province;
export const CalendarIcon = Calendar;
export const RegisterIcon = Register;
export const ServicesIcon = Services;
export const SettingsIcon = Settings;
export const RotationIcon = Rotation;
export const VolumeOffIcon = VolumeOff;
export const EditEmailIcon = EditEmail;
export const PostPhoneIcon = PostPhone;
export const ArrowLeftIcon = ArrowLeft;
export const HairColorIcon = HairColor;
export const InstagramIcon = Instagram;
export const EditPhoneIcon = EditPhone;
export const LogoSimpleIcon = LogoSimple;
export const SmartPhoneIcon = SmartPhone;
export const CreditCardIcon = CreditCard;
export const UploadPostIcon = UploadPost;
export const SuccessfulIcon = Successful;
export const ArrowRightIcon = ArrowRight;
export const DescriptionIcon = Description;
export const ArrowDropUpIcon = ArrowDropUp;
export const ProfileNameIcon = ProfileName;
export const SuggestionsIcon = Suggestions;
export const HideProfileIcon = HideProfile;
export const ProfileGridIcon = ProfileGrid;
export const ProfileFeedIcon = ProfileFeed;
export const ShowPasswordIcon = ShowPassword;
export const ExternalLinkIcon = ExternalLink;
export const HidePasswordIcon = HidePassword;
export const FilterActiveIcon = FilterActive;
export const ArrowDropDownIcon = ArrowDropDown;
export const NaughtyPhraseIcon = NaughtyPhrase;
export const EnableProfileIcon = EnableProfile;
export const DeleteAccountIcon = DeleteAccount;
export const NavBarOptionsIcon = HamburgerMenu;
export const PaymentMethodsIcon = PaymentMethods;
export const AspectOriginalIcon = AspectOriginal;
export const TelegramFilledIcon = TelegramFilled;
export const ForgotPasswordIcon = ForgotPassword;
export const ContactsFilterIcon = ContactsFilter;
export const CryptoCurrencyIcon = CryptoCurrency;
export const WhatsappFilledIcon = WhatsappFilled;
export const WhatsappOutlineIcon = WhatsappOutline;
export const TelegramOutlineIcon = TelegramOutline;
export const AddExternalLinkIcon = AddExternalLink;
export const ProfileUserNameIcon = ProfileUserName;
export const HighlightProfileIcon = HighlightProfile;
export const SexualOrientationIcon = SexualOrientation;
export const DetailProfileCardIcon = DetailProfileCard;
export const HighlightedProfileIcon = HighlightedProfile;
export const EditBasicProfileIcon = EditBasicInformation;
