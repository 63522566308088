import React from 'react';
import { Area } from 'react-easy-crop';
import { EAspect,ImageFileInput } from 'app/module/profile/presentation/component/imageFileInput/ImageFileInput';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';

interface IPostImageFormFieldProps {
    watchImage:  File
    showError: boolean
    errorMessage: string
    onChange: (imageData: File, areaPixels: Area, rotation: number, aspect: EAspect) => void
}

export const PostImageFormField = (props: IPostImageFormFieldProps): JSX.Element => {
    return (
        <>
            <ImageFileInput
                isPost
                onChange={props.onChange}
                watchImage={props.watchImage}
            />
            <SexonInputErrorMessage
                showError={props.showError}
                message={props.errorMessage}
            />
        </>
    );
};
