import React,{ useCallback,useMemo,useRef,useState } from 'react';
import './ImageFileInput.scss';
import {
    Aspect1Icon,
    Aspect2Icon,
    Aspect3Icon,
    AspectOriginalIcon,
    CameraIcon,
    RotationIcon,
    ZoomIcon
} from 'assets/icon/sexon/svg/icons';
import setToastErrorUserCase from 'app/common/domain/useCase/SetToastErrorUseCase';
import { useTranslation } from 'react-i18next';
import {
    ADD_PROFILE_IMAGE,
    ASPECT_1,
    ASPECT_2,
    ASPECT_3,
    ASPECT_ORIGINAL,
    IMAGE_TYPE_ERROR,
    ROTATION,
    SELECT_ANOTHER_IMAGE,
    ZOOM
} from 'translations/tr';
import Cropper,{ Area,Point } from 'react-easy-crop';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import {
    ESexonSliderPosition,
    SexonSliderComponent
} from 'app/common/presentation/component/slider/SexonSliderComponent';
import { ESexonLazyImageType,SexonLazyImage } from 'app/common/presentation/component/lazyImage/SexonLazyImage';
import { PostSkeleton } from 'app/module/profile/presentation/component/postSkeleton/PostSkeleton';
import { EPostTypeValue,PostType } from 'app/module/profile/domain/valueObject/PostType';

interface IImageFileInputProps{
    isPost: boolean
    watchImage: File | string
    onChange: (imageData: File,areaPixels: Area,rotation: number,aspect: EAspect) => void
}

export const enum EAspect{
    ORIGINAL = 'Original',
    ASPECT1 = '1:1',
    ASPECT2 = '4:5',
    ASPECT3 = '16:9',
}

// TODO(Álvaro) Refactor and split in two components
export const ImageFileInput = (props: IImageFileInputProps): JSX.Element => {
    const { t } = useTranslation();
    const [ zoom,setZoom ] = useState(3);
    const [ rotation,setRotation ] = useState(0);
    const [ aspect,setAspect ] = useState(EAspect.ORIGINAL);
    const [ imageFile,setImageFile ] = useState<File>();
    const [ croppedAreaPixels,setCroppedAreaPixels ] = useState<Area>(
        {
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        }
    );

    const [ crop,setCrop ] = useState<Point>({ x: 0,y: 0 });
    const fileInputRef = useRef<HTMLInputElement>(null);
    const openInput = (): void => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (): void => {
        const file = fileInputRef.current?.files?.[0];
        if( file ){
            const allowedTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/webp',
                'image/bmp',
                'image/bmp',
                'image/apng'
            ];

            if( allowedTypes.includes(file.type) ){
                const reader = new FileReader();
                reader.onload = (): void => {
                    props.onChange(file,croppedAreaPixels,rotation,aspect);
                };
                reader.readAsDataURL(file);
                setImageFile(file);
            }else{
                setToastErrorUserCase(t(IMAGE_TYPE_ERROR));
            }
        }
    };

    const onCropComplete = useCallback(
        (croppedArea: Area,croppedAreaPixelsValues: Area) => {
            setCroppedAreaPixels(croppedAreaPixelsValues);
            if( imageFile ){
                props.onChange(imageFile,croppedAreaPixelsValues,rotation,aspect);
            }
        },
        [ imageFile,aspect,rotation ]
    );

    const renderProfileContainer = (): JSX.Element => {
        return (
            <div className="image-file-input-profile-container">
                <input
                    type="file"
                    accept="image/*"
                    ref={ fileInputRef }
                    onChange={ handleFileChange }
                />

                { props.watchImage ? (
                    <img
                        alt=""
                        src={
                            typeof props.watchImage === 'string' ?
                                props.watchImage :
                                URL.createObjectURL(props.watchImage)
                        }
                    />
                ) : (
                    <div className="profile-default-icon-container">
                        <CameraIcon className="icon"/>
                    </div>
                ) }

                <SexonButton
                    type="button"
                    onClick={ openInput }
                    styling={ ESexonButtonStyle.FILLED }
                    title={ t(ADD_PROFILE_IMAGE).toUpperCase() }
                />
            </div>
        );
    };

    const handleCropperAspect = (): number => {
        switch( aspect ){
            case EAspect.ASPECT1:
                return 1;
            case EAspect.ASPECT2:
                return 4 / 5;
            default:
            case EAspect.ASPECT3:
                return 16 / 9;

        }
    };

    const handleSelectAspectRadioButtonStyle = (aspectType: EAspect): ESexonButtonStyle => {
        if( aspect === aspectType ){
            return ESexonButtonStyle.BASIC_PRIMARY;
        }

        return ESexonButtonStyle.BASIC;
    };


    const renderPostContainer = (): JSX.Element => {
        const handleImageType = (): JSX.Element => {
            if( aspect !== EAspect.ORIGINAL ){
                return (
                    <Cropper
                        crop={ crop }
                        zoom={ zoom }
                        maxZoom={ 5 }
                        zoomSpeed={ 0.2 }
                        cropShape="rect"
                        rotation={ rotation }
                        aspect={ handleCropperAspect() }
                        onCropComplete={ onCropComplete }
                        onCropChange={ (e): void => {
                            setCrop({
                                x: e.x,
                                y: e.y
                            });
                        } }
                        image={
                            typeof props.watchImage === 'string' ?
                                props.watchImage :
                                URL.createObjectURL(props.watchImage)
                        }
                    />
                );
            }

            return (
                <SexonLazyImage
                    showBlurBackground
                    isProcessing={ false }
                    onChange={ handleFileChange }
                    type={ ESexonLazyImageType.RECTANGLE }
                    src={
                        typeof props.watchImage === 'string'
                            ?
                            props.watchImage
                            :
                            URL.createObjectURL(props.watchImage) }
                />
            );
        };

        return (
            <>
                <div className="image-file-input-post-container">
                    <input
                        type="file"
                        accept="image/*"
                        ref={ fileInputRef }
                        onChange={ handleFileChange }
                    />

                    { props.watchImage ?
                        (
                            handleImageType()
                        ) : (
                            <PostSkeleton
                                onClick={openInput}
                                type={new PostType(EPostTypeValue.IMAGE)}
                            />
                        ) }
                </div>

                {
                    props.watchImage ? (
                        <div className="post-image-actions-container">
                            {
                                aspect !== EAspect.ORIGINAL ? (
                                    <div className="post-image-sliders-container">
                                        <SexonSliderComponent
                                            min={ 1 }
                                            max={ 5 }
                                            step={ 0.1 }
                                            range={ false }
                                            values={ zoom }
                                            title={ t(ZOOM) }
                                            icon={ <ZoomIcon/> }
                                            allowCross={ false }
                                            position={ESexonSliderPosition.BELOW}
                                            onChange={ (e): void => {
                                                setZoom(Number(e));
                                            } }
                                        />
                                        <SexonSliderComponent
                                            min={ 0 }
                                            max={ 360 }
                                            step={ 90 }
                                            range={ false }
                                            values={ rotation }
                                            allowCross={ false }
                                            title={ t(ROTATION) }
                                            icon={ <RotationIcon/> }
                                            position={ESexonSliderPosition.BELOW}
                                            onChange={ (e): void => {
                                                setRotation(Number(e));
                                            } }
                                        />
                                    </div>
                                ) : null
                            }

                            <div className="post-image-buttons-container">
                                <div className="aspect-radio-buttons-container">
                                    <SexonButton
                                        type="button"
                                        styling={ handleSelectAspectRadioButtonStyle(EAspect.ORIGINAL) }
                                        onClick={ (): void => {
                                            setAspect(EAspect.ORIGINAL);
                                            if( imageFile ){
                                                props.onChange(imageFile,croppedAreaPixels,rotation,EAspect.ORIGINAL);
                                            }
                                        } }
                                        title={ (
                                            <>
                                                <span>
                                                    { t(ASPECT_ORIGINAL) }
                                                </span>
                                                <AspectOriginalIcon/>
                                            </>) }
                                    />
                                    <SexonButton
                                        type="button"
                                        styling={ handleSelectAspectRadioButtonStyle(EAspect.ASPECT1) }
                                        onClick={ (): void => {
                                            setAspect(EAspect.ASPECT1);
                                        } }
                                        title={ (
                                            <>
                                                <span>
                                                    { t(ASPECT_1) }
                                                </span>
                                                <Aspect1Icon/>
                                            </>) }
                                    />
                                    <SexonButton
                                        type="button"
                                        styling={ handleSelectAspectRadioButtonStyle(EAspect.ASPECT2) }
                                        onClick={ (): void => {
                                            setAspect(EAspect.ASPECT2);
                                        } }
                                        title={ (
                                            <>
                                                <span>
                                                    { t(ASPECT_2) }
                                                </span>
                                                <Aspect2Icon/>
                                            </>) }
                                    />
                                    <SexonButton
                                        type="button"
                                        styling={ handleSelectAspectRadioButtonStyle(EAspect.ASPECT3) }
                                        onClick={ (): void => {
                                            setAspect(EAspect.ASPECT3);
                                        } }
                                        title={ (
                                            <>
                                                <span>
                                                    { t(ASPECT_3) }
                                                </span>
                                                <Aspect3Icon/>
                                            </>) }
                                    />
                                </div>

                                <div className="new-image-button-container">
                                    <SexonButton
                                        type="button"
                                        onClick={ openInput }
                                        styling={ ESexonButtonStyle.FILLED }
                                        title={ t(SELECT_ANOTHER_IMAGE).toString() }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </>
        );
    };

    // I use useMemo to prevent unnecessary rendering every time a form state changes,
    // as there was a slowdown in the application with heavy images.
    return useMemo(() => {
        return props.isPost ? renderPostContainer() : renderProfileContainer();
    },[ props.watchImage,crop.x,crop.y,imageFile,aspect,zoom,rotation ]);
};
