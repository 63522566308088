import { useForm,UseFormReturn } from 'react-hook-form';
import {
    ECreatePostDataName,
    ICreatePostFormData
} from 'app/common/presentation/component/popUpManager/createPostModal/form/CreatePostFormData';
import setFullScreenSpinnerLoadingUserCase from 'app/common/domain/useCase/SetFullScreenSpinnerLoadingUseCase';
import setToastSuccessfulUserCase from 'app/common/domain/useCase/SetToastSuccessfulUseCase';
import setToastErrorUserCase from 'app/common/domain/useCase/SetToastErrorUseCase';
import { translateApiError } from 'app/common/domain/entity/Error';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CREATE_POST_SUCCESSFUL_MESSAGE,GENERIC_API_ERROR,INVALID_TRIMMED_VIDEO_DURATION } from 'translations/tr';
import { BaseSyntheticEvent,useContext,useState } from 'react';
import createPostUserCase from 'app/module/profile/domain/useCase/post/CreatePostUseCase';
import { ReduxStore } from 'app/common/presentation/redux/ReduxStore';
import { FeedFiltersActions } from 'app/common/presentation/redux/feed/FeedFiltersSlice';
import { FeedActions } from 'app/common/presentation/redux/feed/FeedSlice';
import { EAspect } from 'app/module/profile/presentation/component/imageFileInput/ImageFileInput';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import uploadImageUseCase from 'app/module/profile/domain/useCase/upload/UploadImageUseCase';
import getCroppedImg from 'app/module/profile/presentation/component/imageFileInput/cropImage';
import { useCurrentProfile } from 'app/common/presentation/hook/useCurrentProfile';
import { useAuthReduxState } from 'app/common/presentation/redux/auth/hook/useAuthReduxState';
import { useDispatch } from 'react-redux';
import { ShowCreatePostModalActions } from 'app/common/presentation/redux/createPostModal/ShowCreatePostModalSlice';
import { NewPostCreatedActions } from 'app/common/presentation/redux/createPostModal/NewPostCreatedSlice';
import { EPostTypeValue } from 'app/module/profile/domain/valueObject/PostType';
import createUploadLinkUseCase from 'app/module/profile/domain/useCase/upload/CreateUploadLinkUseCase';
import * as tus from 'tus-js-client';

interface IUseCreatePostModalControllerResponse {
    closeModal: () => void
    isUploadingVideo: boolean
    percentageUploaded: number
    createPostUseForm: UseFormReturn<ICreatePostFormData, any>
    submitCreatePostForm: (e?: (BaseSyntheticEvent<object, any, any> | undefined)) => Promise<void>
}

export const useCreatePostModalController = (): IUseCreatePostModalControllerResponse => {
    const auth = useAuthReduxState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const UseCurrentProfile = useCurrentProfile();
    const dic = useContext(DependencyInjectionContext);
    const [isUploadingVideo, setIsUploadingVideo] = useState(false);
    const [percentageUploaded, setPercentageUploaded] = useState(0);
    const createPostUseForm = useForm<ICreatePostFormData>({
        defaultValues: {
            type: EPostTypeValue.IMAGE,
            description: '',
            image: {
                value: undefined,
                areaPixels: undefined,
                rotation: 0,
                aspect: EAspect.ORIGINAL,
            },
            video: {
                value: undefined,
                start: 0,
                end: 0
            }
        },
        shouldFocusError: true,
    });

    const closeModal = (): void => {
        createPostUseForm.reset();
        dispatch(ShowCreatePostModalActions.handleHideCreatePostModal());
    };

    const afterCreatingPost = (): void => {
        closeModal();
        createPostUseForm.reset();
        ReduxStore.dispatch(FeedActions.reset());
        ReduxStore.dispatch(FeedFiltersActions.reset());
        setFullScreenSpinnerLoadingUserCase(false);
        setIsUploadingVideo(false);
        setPercentageUploaded(0);
        setToastSuccessfulUserCase(t(CREATE_POST_SUCCESSFUL_MESSAGE));
        navigate(`/${ UseCurrentProfile.currentProfile.userName.trim() }/`);
        ReduxStore.dispatch(NewPostCreatedActions.handleNotifyNewPostModal());
    };

    const createImagePost = async (data: ICreatePostFormData): Promise<void> => {
        try {
            const watchImage = createPostUseForm.watch(ECreatePostDataName.IMAGE);
            let croppedImageValue;

            if (data.image.aspect !== EAspect.ORIGINAL) {
                croppedImageValue = await getCroppedImg(
                    URL.createObjectURL(watchImage.value),
                    watchImage.areaPixels,
                    watchImage.rotation
                );
            } else {
                croppedImageValue = watchImage.value;
            }

            if (croppedImageValue) {
                const uploadedImage = await uploadImageUseCase({
                    auth,
                    image: croppedImageValue,
                    uploadRepository: dic.uploadRepository
                });

                await createPostUserCase({
                    auth,
                    data,
                    image: uploadedImage.url,
                    postRepository: dic.postRepository
                });

                afterCreatingPost();
            }
        } catch (error) {
            setToastErrorUserCase(translateApiError(error as string));
            setFullScreenSpinnerLoadingUserCase(false);
        }
    };

    const uploadToCloudFare = (file: File, url: string, uid: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const endpoint = 'upload/tus-upload/';
            const tusUrl = `${process.env.REACT_APP_API_BASE_URL}${endpoint}${uid}`;

            const upload = new tus.Upload(file, {
                endpoint: tusUrl,
                chunkSize: 5 * 1024 * 1024,
                uploadSize: file.size,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                },
                onError: (): void  => {
                    reject(t(GENERIC_API_ERROR));
                },
                onProgress: (bytesUploaded, bytesTotal): void  => {
                    const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                    setPercentageUploaded(Number(percentage));
                },
                onSuccess: (): void => {
                    resolve(url);
                },
            });

            upload.start();
        });
    };

    const createVideoPost = async (data: ICreatePostFormData): Promise<void> => {
        try {
            // Get upload link
            const link = await createUploadLinkUseCase({
                startTime: data.video.start,
                endTime: data.video.end,
                duration: data.video.duration,
                size: data.video.size,
                auth,
                uploadRepository: dic.uploadRepository
            });

            // Upload to CloudFare
            await uploadToCloudFare(data.video.value, link.url, link.uid);

            // Create post
            await createPostUserCase({
                auth,
                data,
                image: link.uid,
                postRepository: dic.postRepository
            });

            afterCreatingPost();
        } catch (error) {
            setToastErrorUserCase(translateApiError(error as string));
            setIsUploadingVideo(false);
            setPercentageUploaded(0);
        }
    };

    const validateVideoTrimDuration = (start: number, end: number): boolean => {
        const videoDuration = end - start;
        const minTrimDuration = 2;
        const maxTrimDuration = 30;

        return videoDuration >= minTrimDuration && videoDuration <= maxTrimDuration;
    };

    const submitCreatePostForm = createPostUseForm.handleSubmit(async(data) => {
        if(data.type === EPostTypeValue.IMAGE){
            setFullScreenSpinnerLoadingUserCase(true);
            await createImagePost(data);
        } else if(!validateVideoTrimDuration(data.video.start, data.video.end)){
            setToastErrorUserCase(t(INVALID_TRIMMED_VIDEO_DURATION));
            setFullScreenSpinnerLoadingUserCase(false);
        } else {
            setIsUploadingVideo(true);
            createVideoPost(data);
        }
    });

    return {
        closeModal,
        isUploadingVideo,
        createPostUseForm,
        percentageUploaded,
        submitCreatePostForm
    };
};
