import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    value: true
};

const slice = createSlice({
    name: 'videoPlayerMuted',
    initialState,
    reducers: {
        setVideoPlayerMuted: (state,action: PayloadAction<boolean>) => {
            state.value = action.payload;
        },
    },
});

export const VideoPlayerMutedReducer = slice.reducer;
export const VideoPlayerMutedActions = slice.actions;
