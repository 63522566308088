import IFeedRepository from 'app/module/profile/domain/interface/FeedRepository';
import { IFeedFilters } from 'app/module/profile/domain/valueObject/FeedFilters';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import IFeed from 'app/module/profile/domain/entity/Feed';
import { GetFeedDataMapper } from 'app/module/profile/data/repository/api/feed/dataMapper/GetFeedDataMapper';
import { Pagination } from 'app/common/domain/valueObject/Pagination';

export class ApiFeedRepository implements IFeedRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async findAll(
        feedFilters: IFeedFilters,
        pagination: Pagination,
    ): Promise<IFeed> {
        try {
            const feedData = await this._sexonApiClient.getFeed(
                GetFeedDataMapper.fromDomain(
                    feedFilters,
                    pagination,
                ),
            );
            return GetFeedDataMapper.fromResponse(
                feedData,
            );
        } catch (error){
            return Promise.reject(error);
        }
    }
}
