import React,{ useState } from 'react';
import './SexonLazyImage.scss';
import { useTranslation } from 'react-i18next';
import { LOADING,PROCESSING_POST_INFO } from 'translations/tr';
import { ProcessingImageSkeleton } from 'app/common/presentation/component/lazyImage/imageSkeleton/processing/ProcessingImageSkeleton';
import { LoadingImageSkeleton } from 'app/common/presentation/component/lazyImage/imageSkeleton/loading/LoadingImageSkeleton';


export enum ESexonLazyImageType{
    ROUND = 'round',
    RECTANGLE = 'rect'
}

interface ISexonLazyImageProps {
    src: string
    id?: string
    className?: string
    onClick?: () => void
    onChange?: () => void
    isProcessing: boolean
    disableSkeleton?: boolean
    type: ESexonLazyImageType
    showBlurBackground?: boolean
}

export const SexonLazyImage = (props: ISexonLazyImageProps): JSX.Element => {
    const { t } = useTranslation();
    const [ isLoaded,setIsLoaded ] = useState(false);

    return props.isProcessing ? (
        <ProcessingImageSkeleton
            type={props.type}
            title={t(PROCESSING_POST_INFO)}
        />
    ) : (
        <div
            onClick={ props.onClick }
            className="component-lazy-image"
        >
            <img
                alt=""
                loading="lazy"
                id={ props.id }
                src={ props.src }
                onChange={ props.onChange }
                className={ props.className }
                onLoad={ () => setIsLoaded(true) }
            />
            {
                props.showBlurBackground ? (
                    <img
                        alt=""
                        loading="lazy"
                        src={ props.src }
                        className="blur-image-background"
                    />
                ) : null
            }
            {
                !isLoaded && !props.disableSkeleton ? (
                    <LoadingImageSkeleton
                        type={props.type}
                        title={t(LOADING)}
                    />
                ) : null
            }
        </div>
    );
};

