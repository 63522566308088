import { MutableRefObject, useEffect, useRef } from 'react';

interface IUseVideoBackgroundResponse {
    canvasRef: MutableRefObject<HTMLCanvasElement | null>
    videoRef: MutableRefObject<HTMLVideoElement | null>
}

export const useVideoBackground = (
    externalVideoRef?: MutableRefObject<HTMLVideoElement | null>
): IUseVideoBackgroundResponse => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const internalVideoRef = useRef<HTMLVideoElement | null>(null);

    // Usa la ref externa si se proporciona, de lo contrario usa la interna
    const videoRef = externalVideoRef || internalVideoRef;

    const init = (): (() => void) => {
        const video = videoRef.current;
        const canvas = canvasRef.current;

        if (!video || !canvas) {
            // eslint-disable-next-line no-empty-function
            return () => {};
        }

        const ctx = canvas.getContext('2d');
        if (!ctx) {
            // eslint-disable-next-line no-empty-function
            return () => {};
        }

        let intervalId: number | null = null;
        const fps = 15;
        const frameInterval = 1000 / fps;

        const draw = (): void => {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        };

        const startDrawing = (): void => {
            if (!intervalId) {
                intervalId = window.setInterval(draw, frameInterval);
            }
        };

        const stopDrawing = (): void => {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };

        video.addEventListener('play', startDrawing, false);
        video.addEventListener('pause', stopDrawing, false);
        video.addEventListener('ended', stopDrawing, false);

        return () => {
            video.removeEventListener('play', startDrawing);
            video.removeEventListener('pause', stopDrawing);
            video.removeEventListener('ended', stopDrawing);
            stopDrawing();
        };
    };

    useEffect(init, [videoRef]);

    return {
        canvasRef,
        videoRef,
    };
};
