import React,{ useContext,useState } from 'react';
import { ACCEPT,AGE_CONSENT_MODAL_TEXT,AGE_CONSENT_MODAL_TITLE,EXIT,FORBIDDEN_ACCESS_UNDER_18 } from 'translations/tr';
import 'app/common/presentation/component/ageConsentModal/AgeConsentModal.scss';
import { useTranslation } from 'react-i18next';
import setAgeConsentUserCase from 'app/common/domain/useCase/SetAgeConsentUseCase';
import { RtaButton } from 'app/common/presentation/component/rta/RtaButton';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { DependencyInjectionContext } from 'app/common/presentation/context/DependecyInjectionContext';
import { useAgeConsentConsentReduxState } from 'app/common/presentation/redux/ageConsent/hook/useAgeConsentConsentReduxState';
import acceptAgeConsentUseCase from 'app/common/domain/useCase/AcceptAgeConsentUseCase';
import { LazyFallbackSpinner } from 'app/common/presentation/component/spinner/lazyFallbackSpinner/LazyFallbackSpinner';
import { SexonModal } from '../modal/SexonModal';
import { SexonButton } from '../button/SexonButton';

export const AgeConsentModal = (): JSX.Element => {
    const { t } = useTranslation();
    const hasConsent = useAgeConsentConsentReduxState();
    const dic = useContext(DependencyInjectionContext);
    const [isLoading, setIsLoading] = useState(false);

    const googleUrl = 'https://www.google.es/';
    const rgpdEmail = process.env.REACT_APP_RGPD_EMAIL;
    const legalNoticeUrl = process.env.REACT_APP_LEGAL_NOTICE_URL;
    const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL;

    const handleAcceptAgeConsent = async (): Promise<void> => {
        setIsLoading(true);
        await acceptAgeConsentUseCase({
            ageConsentRepository: dic.ageConsentRepository
        }).then(()=> {
            setAgeConsentUserCase({
                storageRepository: dic.storageRepository
            }).finally(()=> {
                setIsLoading(false);
            });
        });
    };

    return (
        <>
            {
                isLoading ? (
                    <LazyFallbackSpinner />
                ) : null
            }
            <SexonModal
                show={ !hasConsent }
                classname="component-age-consent-modal"
                banner={(
                    <div className="age-consent-modal-banner">
                        <div className="icon-container">
                            <RtaButton />
                        </div>
                        <p className="age-consent-modal-banner-info">
                            {t(FORBIDDEN_ACCESS_UNDER_18).toUpperCase()}
                        </p>
                    </div>
                )}
                headerTitle={ t(AGE_CONSENT_MODAL_TITLE) }
                body={ (
                    <div className="age-consent-modal-body-container">
                        <p
                            dangerouslySetInnerHTML={ { __html: t(
                                AGE_CONSENT_MODAL_TEXT,
                                {
                                    privacyPolicyUrl,
                                    legalNoticeUrl,
                                    rgpdEmail
                                }
                            ).toString() } }
                        />
                    </div>
                ) }

                footer={(
                    <div className="age-consent-modal-footer-container">
                        <SexonButton
                            type="button"
                            title={ t(EXIT).toUpperCase()}
                            styling={ESexonButtonStyle.BASIC_PRIMARY}
                            className="age-consent-modal-footer-exit-button"
                            onClick={(): void=> {
                                window.location.replace(googleUrl);
                            }}
                        />
                        <SexonButton
                            type="button"
                            disabled={isLoading}
                            title={ t(ACCEPT).toUpperCase()}
                            onClick={handleAcceptAgeConsent}
                            styling={ESexonButtonStyle.FILLED}
                            className="age-consent-modal-footer-accept-button"
                        />
                    </div>
                )}
            />
        </>
    );
};
