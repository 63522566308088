import IPostRepository from 'app/module/profile/domain/interface/PostRepository';
import { ICreatePostFormData } from 'app/common/presentation/component/popUpManager/createPostModal/form/CreatePostFormData';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { EPostStatus,IPost } from 'app/module/profile/domain/entity/Post';
import { ulid } from 'ulid';
import { PostType } from 'app/module/profile/domain/valueObject/PostType';

interface ICreatePostUseCaseProps {
    auth: IAuth
    image: string
    data: ICreatePostFormData
    postRepository: IPostRepository
}

export default async(props: ICreatePostUseCaseProps): Promise<void> => {
    try{
        const post: IPost = {
            likes: 0,
            id: ulid(),
            image: props.image,
            smallImage: props.image,
            publishDate: new Date(),
            status: EPostStatus.PROCESSING,
            type: new PostType(props.data.type),
            description: props.data.description,
        };

        return await props.postRepository.add(post, props.auth);
    }catch( error ){
        return Promise.reject(error);
    }
};
