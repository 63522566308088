import { Area } from 'react-easy-crop';
import { EAspect } from 'app/module/profile/presentation/component/imageFileInput/ImageFileInput';
import { EPostTypeValue } from 'app/module/profile/domain/valueObject/PostType';

export interface ICreatePostFormData{
    type: EPostTypeValue
    description: string
    image: {
        value: File
        areaPixels: Area
        rotation: number
        aspect: EAspect
    }
    video: {
        value: File
        start: number
        end: number
        duration: number
        size: number
    }
}

export enum ECreatePostDataName{
    TYPE = 'type',
    IMAGE = 'image',
    IMAGE_VALUE = 'image.value',
    VIDEO = 'video',
    VIDEO_VALUE = 'video.value',
    VIDEO_START = 'video.start',
    VIDEO_END = 'video.end',
    VIDEO_DURATION= 'video.duration',
    VIDEO_SIZE = 'video.size',
    DESCRIPTION = 'description',
}
