export enum EAppRoute {
    SIGN_IN = '/sign-in',
    SIGN_UP = '/sign-up',
    VERIFY_ACCOUNT = '/verify/:token',
    RESET_PASSWORD = '/reset-password/:token',
    EDIT_EMAIL = '/edit/email',
    VALIDATE_EMAIL_CHANGE = '/validate/email-change/:token',
    EDIT_PHONE = '/edit/phone',

    HOME = '/',
    DELETE_ACCOUNT = '/delete/account',
    HIDE_PROFILE = '/hide/profile',
    CREATE_PROFILE = '/create/profile',
    PROFILE_DETAIL='/:profileUserName/*',
    PROFILE_DETAIL_POSTS='/:profileUserName',
    PROFILE_DETAIL_FEED='/:profileUserName/feed',
    EDIT_PROFILE='/edit/profile/*',
    EDIT_BASIC_PROFILE='/edit/profile/basic-information',
    EDIT_ADDITIONAL_PROFILE='/edit/profile/additional-information',
    HIGHLIGHT_PROFILE='/highlight/profile',


    PAYMENT_OK='/payment/ok',
    PAYMENT_KO='/payment/ko',
}
