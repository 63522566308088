export enum EPostTypeValue {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}

export class PostType {
    private _value: EPostTypeValue;

    public constructor(value: EPostTypeValue) {
        this._value = value;
    }

    public get value(): EPostTypeValue {
        return this._value;
    }

    public get isVideo(): boolean{
        return this._value === EPostTypeValue.VIDEO;
    }

    public get isImage(): boolean{
        return this._value === EPostTypeValue.IMAGE;
    }
}
