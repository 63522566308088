import { PostType } from 'app/module/profile/domain/valueObject/PostType';

export interface IPost {
    id: string
    image: string
    likes: number
    type: PostType
    publishDate: Date
    smallImage: string
    description: string
    status: EPostStatus
}

// TODO (Álvaro): move to VO file and review all entities
export enum EPostStatus {
    READY= 'READY',
    PROCESSING = 'PROCESSING'
}
