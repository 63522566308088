import RegexResource from 'app/common/resource/RegexResource';

export class Email {

    private _value: string;

    public constructor(value: string) {
        this._ensureIsValid(value);
        this._value = value.toLowerCase().trim();
    }

    get value(): string {
        return this._value;
    }

    private _ensureIsValid(value: string): void {
        if(!RegexResource.EMAIL.test(value)){
            throw new Error(`Email invalid: ${value}`);
        }
    }

}
