import React from 'react';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';
import { VideoUploader } from 'app/module/profile/presentation/component/videoUploader/VideoUploader';

interface IPostVideoFormFieldProps {
    showError: boolean
    errorMessage: string
    onChange: (videoData: File, start: number, end: number, duration: number, size: number) => void
}

export const PostVideoFormField = (props: IPostVideoFormFieldProps): JSX.Element => {
    return (
        <>
            <VideoUploader
                onChange={props.onChange}
            />
            <SexonInputErrorMessage
                showError={props.showError}
                message={props.errorMessage}
            />
        </>
    );
};
