import IProfileRepository from 'app/module/profile/domain/interface/ProfileRepository';
import { Report } from 'app/module/profile/domain/valueObject/Report';
import { IReportFormData } from 'app/common/presentation/component/popUpManager/reportModal/form/ReportFormData';

interface IReportProfileUseCaseProps {
    profileId: string
    data: IReportFormData
    profileRepository: IProfileRepository
}

export default async (props: IReportProfileUseCaseProps): Promise<void> => {
    try {
        const report = new Report(
            props.profileId,
            props.data.reason,
            props.data.description,
            props.data.email,
            props.data.fullName,
        );

        return await props.profileRepository.reportProfile(report);
    } catch (error) {
        return Promise.reject(error);
    }
};
