export class UploadLink {
    public constructor(
        private _uid: string,
        private _url: string
    ) {}

    public get uid(): string {
        return this._uid;
    }

    public get url(): string {
        return this._url;
    }
}
