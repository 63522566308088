import React,{ ReactElement } from 'react';
import './UploadingVideoSpinner.scss';
import { UPLOADING_VIDEO } from 'translations/tr';
import { useTranslation } from 'react-i18next';

interface IUploadingVideoSpinnerProps {
    percentage: number
}

export const UploadingVideoSpinner = (props: IUploadingVideoSpinnerProps): ReactElement => {
    const { t } = useTranslation();
    const radius = 55;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (circumference * props.percentage) / 100; // Calcula el desplazamiento


    return (
        <div className="component-uploading-video-spinner">
            <div className="uploading-video-spinner-container">
                <div className="uploading-video-spinner-ring">
                    <svg
                        className="spinner-svg"
                        viewBox="0 0 120 120"
                    >
                        <circle
                            cx="60"
                            cy="60"
                            r={ radius }
                            className="spinner-ring-background"
                        />
                        <circle
                            cx="60"
                            cy="60"
                            r={ radius }
                            className="spinner-ring-progress"
                            style={ {
                                strokeDashoffset,
                            } }
                        />
                    </svg>
                </div>
                <div className="percentage-text">
                    { Math.round(props.percentage) }%
                </div>
                <div className="info-text">
                    {t(UPLOADING_VIDEO)}
                </div>
            </div>
        </div>
    );
};
