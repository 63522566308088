import { EPostStatus,IPost } from 'app/module/profile/domain/entity/Post';
import { EPostStatusResponse,IPostResponse } from 'app/common/data/remote/sexonApi/dto/response/PostResponse';
import { PostTypeDataMapper } from 'app/module/profile/data/repository/api/post/dataMapper/PostTypeDataMapper';

export class PostDataMapper {

    private static _statusFromResponse(statusResponse: EPostStatusResponse): EPostStatus {
        switch(statusResponse){
            case EPostStatusResponse.PROCESSING:
                return EPostStatus.PROCESSING;
            default:
            case EPostStatusResponse.READY:
                return EPostStatus.READY;
        }
    }

    public static fromArrayResponse(postsResponse: IPostResponse[]): IPost[] {
        const posts: IPost[] = [];

        postsResponse.forEach((postResponse) => {
            posts.push(this.fromResponse(postResponse));
        });

        return posts;
    }

    public static fromResponse(postResponse: IPostResponse): IPost {
        return {
            id: postResponse.id,
            image: postResponse.image ?? '',
            likes: postResponse.likes,
            smallImage: postResponse.imageSmall ?? '', // TODO: refactor entity, this is because when post is processing back does not send the small image neither image
            description: postResponse.description,
            publishDate: new Date(postResponse.publishDate),
            status: this._statusFromResponse(postResponse.status),
            type: PostTypeDataMapper.fromResponse(postResponse.type)
        };
    }
}
