import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { ICreateUploadLinkRequest } from 'app/common/data/remote/sexonApi/dto/request/CreateUploadLinkRequest';

export class CreateUploadLinkRequestDataMapper {

    public static fromDomain(
        startTime: number,
        endTime: number,
        duration: number,
        size: number,
        auth: IAuth
    ): ICreateUploadLinkRequest {
        return {
            payload: {
                startTime,
                endTime,
                duration,
                size,
            },
            token: auth.token.value
        };
    }
}
