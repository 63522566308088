import IUploadRepository from 'app/module/profile/domain/interface/UploadRepository';
import { IUploadedImage } from 'app/module/profile/domain/entity/UploadedImage';
import { IAuth } from 'app/module/auth/domain/entity/Auth';

interface IUploadImageUseCaseProps {
    image: File
    auth: IAuth
    uploadRepository: IUploadRepository
}

export default async (props: IUploadImageUseCaseProps): Promise<IUploadedImage> => {
    try {
        return await props.uploadRepository.uploadImage(
            props.image,
            props.auth
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
