import IPostRepository from 'app/module/profile/domain/interface/PostRepository';
import { IPost } from 'app/module/profile/domain/entity/Post';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { CreatePostDataMapper } from 'app/module/profile/data/repository/api/post/dataMapper/CreatePostDataMapper';
import { PostDataMapper } from 'app/module/profile/data/repository/api/post/dataMapper/PostDataMapper';
import { Pagination } from 'app/common/domain/valueObject/Pagination';


export class ApiPostRepository implements IPostRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async add(
        post: IPost,
        auth: IAuth
    ): Promise<void> {
        try {
            return await this._sexonApiClient.createPost(
                CreatePostDataMapper.fromDomain(post, auth),
            );
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async delete(
        post: IPost,
        auth: IAuth
    ): Promise<void> {
        try {
            return await this._sexonApiClient.deletePost(
                {
                    id: post.id,
                    token: auth.token.value,
                }
            );
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async findAll(
        userName: string,
        pagination: Pagination,
        auth: IAuth
    ): Promise<IPost[]> {
        try {
            const data =  await this._sexonApiClient.getPosts({
                payload: {
                    page: pagination.page,
                    limit: pagination.limit
                },
                userName,
                token: auth.token.value
            });

            return PostDataMapper.fromArrayResponse(data);
        } catch (error){
            return Promise.reject(error);
        }
    }

}
