import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: false
};

const slice = createSlice({
    name: 'newPostCreated',
    initialState,
    reducers: {
        handleNotifyNewPostModal: (state) => {
            state.value = true;
        },
        reset: (state) => {
            state.value = false;
        },
    },
});

export const NewPostCreatedReducer = slice.reducer;
export const NewPostCreatedActions = slice.actions;
