export interface IPostResponse {
    id: string
    image: string
    likes: number
    imageSmall: string
    publishDate: number
    description: string
    type: EPostTypeResponse
    status: EPostStatusResponse
}

export enum EPostStatusResponse {
    READY= 'READY',
    PROCESSING = 'PROCESSING'
}

export enum EPostTypeResponse {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
}
