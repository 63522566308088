import { EPostTypeResponse } from 'app/common/data/remote/sexonApi/dto/response/PostResponse';
import { EPostTypeValue,PostType } from 'app/module/profile/domain/valueObject/PostType';

export class PostTypeDataMapper {

    private static fromDomain(postType: PostType): EPostTypeResponse {
        switch(postType.value){
            case EPostTypeValue.VIDEO:
                return EPostTypeResponse.VIDEO;
            case EPostTypeValue.IMAGE:
                return EPostTypeResponse.IMAGE;
            default:
                throw new Error('Invalid post type');
        }
    }

    public static fromResponse(postTypeResponse: EPostTypeResponse): PostType {
        switch(postTypeResponse){
            case EPostTypeResponse.VIDEO:
                return new PostType(EPostTypeValue.VIDEO);
            case EPostTypeResponse.IMAGE:
                return new PostType(EPostTypeValue.IMAGE);
            default:
                throw new Error('Invalid post type response');
        }
    }
}
