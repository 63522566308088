import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './SexonSliderComponent.scss';
import { SliderRangeInfo } from 'app/common/presentation/component/slider/rangeInfo/SexonSliderRangeInfoComponent';

export enum ESexonSliderPosition {
    ABOVE = 'ABOVE',
    BELOW = 'BELOW'
}

interface ISexonSliderComponentProps {
    max: number
    min: number
    step: number
    title: string
    range: boolean
    minTitle?: string
    maxTitle?: string
    icon?: JSX.Element
    allowCross: boolean
    values: number | number[]
    position: ESexonSliderPosition
    onChange: (values: number | number[]) => void
}

export const SexonSliderComponent = (props: ISexonSliderComponentProps): JSX.Element => {
    return (
        <div className="component-slider">
            <p className="slider-title">
                { props.icon }
                { props.title }
            </p>

            { props.position === ESexonSliderPosition.ABOVE ? (
                <SliderRangeInfo
                    maxTitle={props.maxTitle}
                    minTitle={props.minTitle}
                    position={props.position}
                />
            ) : null}

            <div className="slider-rail">
                <Slider
                    max={props.max}
                    min={props.min}
                    step={props.step}
                    range={props.range}
                    value={props.values}
                    onChange={(value): void => props.onChange(value)}
                    allowCross={props.allowCross}
                />
            </div>

            { props.position === ESexonSliderPosition.BELOW ? (
                <SliderRangeInfo
                    maxTitle={props.maxTitle}
                    minTitle={props.minTitle}
                    position={props.position}
                />
            ) : null}

        </div>
    );
};
