import { IReportProfileRequest } from 'app/common/data/remote/sexonApi/dto/request/ReportProfileRequest';
import { ReportReasonDataMapper } from 'app/module/profile/data/repository/api/profile/dataMapper/ReportReasonDataMapper';
import { Report } from 'app/module/profile/domain/valueObject/Report';

export class ReportProfileDataMapper {

    public static fromDomain(report: Report): IReportProfileRequest {
        return {
            payload: {
                reason: ReportReasonDataMapper.fromDomain(report.reason),
                description: report.description,
                email: report.email,
                fullName: report.fullName
            },
            profileId: report.profileIdToReport
        };
    }
}
