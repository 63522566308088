import React,{ ReactElement,useEffect,useState } from 'react';
// @ts-ignore
import videojs,{ VideoJsPlayer,VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import '../VideoUploader.scss';
import { useVideoBackground } from 'app/module/profile/presentation/hook/useVideoBackground';

interface IVideoUploaderPreviewProps {
    endTime: number
    videoSrc: string
    fileType: string
    startTime: number
    onExceedDuration: () => void
    onReady: (duration: number) => void
}

export const VideoUploaderPreview = (props: IVideoUploaderPreviewProps): ReactElement => {
    const { videoRef, canvasRef } = useVideoBackground();
    const [player, setPlayer] = useState<VideoJsPlayer | null>(null);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (videoRef.current && props.videoSrc && props.fileType) {
            const options: VideoJsPlayerOptions = {
                fluid: true,
                responsive: true,
                controls: true,
                autoplay: true,
                tracks: undefined,
                preload: 'auto',
                loop: true,
                muted: true,
                controlBar: {
                    fullscreenToggle: false,
                    pictureInPictureToggle: false,
                    progressControl: false,
                    volumePanel: true,
                    playToggle: false,
                    remainingTimeDisplay: false,
                },
                sources: [{ src: props.videoSrc, type: props.fileType }],
            };

            const newPlayer = videojs(videoRef.current, options);

            newPlayer.on('loadedmetadata', () => {
                const duration = newPlayer.duration();
                if (duration) {
                    const maxDuration = 10 * 60; // 10 minutes

                    if (Math.floor(duration) > maxDuration) {
                        props.onExceedDuration();
                    } else {
                        props.onReady(Math.floor(duration));
                    }
                }
            });

            setPlayer(newPlayer);

            return () => {
                newPlayer.dispose();
                setPlayer(null);
            };
        }
    }, [props.videoSrc, props.fileType]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (player) {
            player.currentTime(props.startTime);
            const interval = setInterval(() => {
                if (player.currentTime() >= props.endTime) {
                    player.currentTime(props.startTime);
                }
            }, 500);

            return () => clearInterval(interval);
        }
    }, [player, props.startTime, props.endTime]);

    return (
        <div
            data-vjs-player="true"
            className="video-container"
        >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */ }
            <video
                playsInline
                ref={ videoRef }
                className="video-js"
            />
            <canvas
                aria-hidden="true"
                className="canvas"
                ref={ canvasRef }
            />
        </div>
    );
};
