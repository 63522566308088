import { FieldError,UseFormRegisterReturn,UseFormReturn } from 'react-hook-form';
import {
    ECreatePostDataName,
    ICreatePostFormData
} from 'app/common/presentation/component/popUpManager/createPostModal/form/CreatePostFormData';
import { useTranslation } from 'react-i18next';
import {
    CURRENCY_SYMBOL_ERROR,
    ERROR_CREATE_POST_IMAGE_REQUIRED,
    ERROR_CREATE_POST_VIDEO_REQUIRED
} from 'translations/tr';
import { EAspect } from 'app/module/profile/presentation/component/imageFileInput/ImageFileInput';
import { EPostTypeValue } from 'app/module/profile/domain/valueObject/PostType';
import RegexResource from 'app/common/resource/RegexResource';
import { Area } from 'react-easy-crop';

interface IUseCreatePostFormControllerProps {
    createPostUseForm: UseFormReturn<ICreatePostFormData, any>
}

interface IUseCreatePostFormControllerResponse {
    watchPostDescription: string
    watchPostType: EPostTypeValue
    postVideoError: FieldError | undefined
    postImageError: FieldError | undefined
    postDescriptionError: FieldError | undefined
    handlePostType: (value: EPostTypeValue) => void
    postDescriptionRegister:  UseFormRegisterReturn<ECreatePostDataName.DESCRIPTION>
    watchPostImage: { value: File; areaPixels: Area;rotation: number; aspect: EAspect }
    onChangeImageField: (imageData: File, areaPixels: Area, rotation: number, aspect: EAspect) => void
    onChangeVideoField: (videoData: File, start: number, end: number, duration: number, size: number) => void
}

export const useCreatePostFormController = (
    props: IUseCreatePostFormControllerProps,
): IUseCreatePostFormControllerResponse => {
    const { t } = useTranslation();

    const watchPostType =  props.createPostUseForm.watch(ECreatePostDataName.TYPE);
    const watchPostDescription = props.createPostUseForm.watch(ECreatePostDataName.DESCRIPTION);
    const watchPostImage = props.createPostUseForm.watch(ECreatePostDataName.IMAGE);

    props.createPostUseForm.register(
        ECreatePostDataName.TYPE,{ required: true, }
    );
    props.createPostUseForm.register(
        ECreatePostDataName.IMAGE_VALUE,{
            required: {
                value: watchPostType === EPostTypeValue.IMAGE,
                message: t(ERROR_CREATE_POST_IMAGE_REQUIRED).toString()
            },
            onChange: () => {
                props.createPostUseForm.clearErrors();
            }
        }
    );
    const postImageError = props.createPostUseForm.formState.errors.image?.value;

    props.createPostUseForm.register(
        ECreatePostDataName.VIDEO_VALUE,{
            required: {
                value: watchPostType === EPostTypeValue.VIDEO,
                message: t(ERROR_CREATE_POST_VIDEO_REQUIRED).toString()
            },
            onChange: () => {
                props.createPostUseForm.clearErrors();
            }
        }
    );
    const postVideoError = props.createPostUseForm.formState.errors.video?.value;

    const postDescriptionRegister = props.createPostUseForm.register(
        ECreatePostDataName.DESCRIPTION,
        {
            pattern: {
                value: RegexResource.CURRENCY_SYMBOLS,
                message: t(CURRENCY_SYMBOL_ERROR).toString(),
            },
        }
    );
    const postDescriptionError = props.createPostUseForm.formState.errors.description;

    const onChangeImageField = (imageData: File, areaPixels: Area, rotation: number, aspect: EAspect): void => {
        props.createPostUseForm.setValue(
            ECreatePostDataName.IMAGE,
            {
                value: imageData,
                areaPixels,
                rotation,
                aspect
            }
        );
        props.createPostUseForm.clearErrors(ECreatePostDataName.IMAGE);
    };

    const onChangeVideoField = (videoData: File, start: number, end: number, duration: number, size: number): void => {
        props.createPostUseForm.setValue(
            ECreatePostDataName.VIDEO,
            {
                value: videoData,
                start,
                end,
                duration,
                size
            }
        );
        props.createPostUseForm.clearErrors(ECreatePostDataName.VIDEO);
    };

    const handlePostType = (value: EPostTypeValue): void => {
        props.createPostUseForm.setValue(ECreatePostDataName.TYPE, value);
        props.createPostUseForm.resetField(ECreatePostDataName.IMAGE);
        props.createPostUseForm.resetField(ECreatePostDataName.VIDEO);
    };

    return {
        watchPostType,
        handlePostType,
        postImageError,
        watchPostImage,
        postVideoError,
        onChangeVideoField,
        onChangeImageField,
        watchPostDescription,
        postDescriptionError,
        postDescriptionRegister,
    };
};
