import React from 'react';
import './InitialLoadingSpinner.scss';
import { LogoIcon } from 'assets/icon/sexon/svg/icons';

export const InitialLoadingSpinner = (): JSX.Element => {
    return (
        <div className="component-initial-loading-spinner">
            <LogoIcon className="logo-spinner" />
        </div>
    );
};
