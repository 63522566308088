import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { DescriptionIcon } from 'assets/icon/sexon/svg/icons';
import { DESCRIPTION_FORM_FIELD_PLACEHOLDER } from 'translations/tr';
import { SexonInputErrorMessage } from 'app/common/presentation/component/inputErrorMessage/SexonInputErrorMessage';
import { SexonTextArea } from 'app/common/presentation/component/textArea/SexonTextArea';

interface IPostDescriptionFormFieldProps {
    register: UseFormRegisterReturn
    showError?: boolean
    errorMessage?: string
    watchRegister?: string
}

export const PostDescriptionFormField = (props: IPostDescriptionFormFieldProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <SexonTextArea
                maxLength={1000}
                autocomplete="off"
                id="postDescription"
                icon={<DescriptionIcon />}
                register={props.register}
                watchRegister={props.watchRegister}
                placeHolder={t(DESCRIPTION_FORM_FIELD_PLACEHOLDER).toString()}
            />
            <SexonInputErrorMessage
                message={props.errorMessage ? props.errorMessage : ''}
                showError={props.showError ? props.showError : false}
            />
        </>
    );
};
