import i18next from 'i18next';
import {
    FRIDAY_LABEL,FULL_WEEK_LABEL,
    MONDAY_LABEL,
    SATURDAY_LABEL,
    SUNDAY_LABEL,
    THURSDAY_LABEL,
    TUESDAY_LABEL,
    WEDNESDAY_LABEL
} from 'translations/tr';

export interface IDayOfWeek {
    value: EDayOfWeek
}

export enum EDayOfWeek {
    MONDAY = 0,
    TUESDAY = 1,
    WEDNESDAY= 2,
    THURSDAY = 3,
    FRIDAY = 4,
    SATURDAY= 5,
    SUNDAY= 6,
}

export const translateDayOfWeek = (dayOfWeek: IDayOfWeek): string => {
    switch(Number(dayOfWeek.value)){
        case EDayOfWeek.MONDAY:
            return i18next.t(MONDAY_LABEL);
        case EDayOfWeek.TUESDAY:
            return i18next.t(TUESDAY_LABEL);
        case EDayOfWeek.WEDNESDAY:
            return i18next.t(WEDNESDAY_LABEL);
        case EDayOfWeek.THURSDAY:
            return i18next.t(THURSDAY_LABEL);
        case EDayOfWeek.FRIDAY:
            return i18next.t(FRIDAY_LABEL);
        case EDayOfWeek.SATURDAY:
            return i18next.t(SATURDAY_LABEL);
        case EDayOfWeek.SUNDAY:
        default:
            return i18next.t(SUNDAY_LABEL);
    }
};

export const translateAvailability = (values: IDayOfWeek[]): string => {
    let translation = '';
    const totalDaysOfWeeks = 7;

    if(values.length === totalDaysOfWeeks){
        return i18next.t(FULL_WEEK_LABEL);
    } if (values.length === 1) {
        translation = translateDayOfWeek(values[0]);
    } else {
        const sortedValues = [...values].sort(
            (a,b) => Number(a.value) - Number(b.value)
        );

        sortedValues.forEach(
            (value, index)=> {
                if(index === values.length || index === 0){
                    translation += translateDayOfWeek(value);
                } else {
                    translation += ` - ${translateDayOfWeek(value)} `;
                }
            });
    }

    return translation;
};

