import IUploadRepository from 'app/module/profile/domain/interface/UploadRepository';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { UploadLink } from 'app/module/profile/domain/entity/UploadLink';

interface ICreateUploadLinkUseCaseProps {
    startTime: number
    endTime: number
    duration: number
    size: number
    auth: IAuth
    uploadRepository: IUploadRepository
}

export default async (props: ICreateUploadLinkUseCaseProps): Promise<UploadLink> => {
    try {
        return await props.uploadRepository.createUploadLink(
            props.startTime,
            props.endTime,
            props.duration,
            props.size,
            props.auth
        );
    } catch (error) {
        return Promise.reject(error);
    }
};
