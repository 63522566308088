import { createSlice } from '@reduxjs/toolkit';
import { EPaymentMethod,IPaymentMethod } from 'app/module/profile/domain/valueObject/PaymentMethod';

const initialState = {
    paymentMethods: [
        { value: EPaymentMethod.CASH },
        { value: EPaymentMethod.BIZUM },
        { value: EPaymentMethod.CARD },
        { value: EPaymentMethod.REVOLUT },
        { value: EPaymentMethod.PAYPAL },
        { value: EPaymentMethod.CRYPTOCURRENCIES },
    ] as IPaymentMethod[]
};

const slice = createSlice({
    name: 'paymentMethods',
    initialState,
    reducers: {},
});

export const PaymentMethodsReducer = slice.reducer;
export const PaymentMethodsActions = slice.actions;
