import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'showCreateFirstPostModal',
    initialState,
    reducers: {
        handleShowCreateFirstPostModal: (state) => {
            state.showModal = true;
        },
        handleHideCreateFirstPostModal: (state) => {
            state.showModal = false;
        },
    },
});

export const ShowCreateFirstPostModalReducer = slice.reducer;
export const ShowCreateFirstPostModalActions = slice.actions;
