import React from 'react';
import './ProfileImage.scss';
import cx from 'classnames';
import {
    ESexonLazyImageType,
    SexonLazyImage
} from 'app/common/presentation/component/lazyImage/SexonLazyImage';

export enum EProfileImageType {
    SMALL = 'small',
    MEDIUM = 'medium',
    BIG = 'big'
}
interface IProfileImageProps {
    image: string
    type: EProfileImageType
    onClick?: () => void
}
export const ProfileImage = (props: IProfileImageProps): JSX.Element => {
    const profileImageClassNames = cx({
        'component-profile-image': true,
        'has-action': !!props.onClick,
        's': props.type === EProfileImageType.SMALL,
        'm': props.type === EProfileImageType.MEDIUM,
        'l': props.type === EProfileImageType.BIG,
    });

    return (
        <div
            className={profileImageClassNames}
        >
            <SexonLazyImage
                src={props.image}
                isProcessing={false}
                onClick={props.onClick}
                type={ESexonLazyImageType.ROUND}
            />
        </div>
    );
};
