import React from 'react';
import { ESexonSliderPosition } from 'app/common/presentation/component/slider/SexonSliderComponent';

interface ISexonSliderComponentProps {
    minTitle?: string
    maxTitle?: string
    position: ESexonSliderPosition
}

export const SliderRangeInfo = (props: ISexonSliderComponentProps): JSX.Element | null => {
    if (!props.minTitle || !props.maxTitle) {
        return null;
    }

    return (
        <div className={`slider-range-info ${props.position.toLowerCase()}`}>
            <span className="slider-range-info-item">
                {props.minTitle}
            </span>
            <span className="slider-range-info-item">
                {props.maxTitle}
            </span>
        </div>
    );
};
