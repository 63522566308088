import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showModal: false
};

const slice = createSlice({
    name: 'showCreatePostModal',
    initialState,
    reducers: {
        handleShowCreatePostModal: (state) => {
            state.showModal = true;
        },
        handleHideCreatePostModal: (state) => {
            state.showModal = false;
        },
    },
});

export const ShowCreatePostModalReducer = slice.reducer;
export const ShowCreatePostModalActions = slice.actions;
