import { IUploadLinkResponse } from 'app/common/data/remote/sexonApi/dto/response/UploadLinkResponse';
import { UploadLink } from 'app/module/profile/domain/entity/UploadLink';

export class UploadLinkDataMapper {

    public static fromResponse(response: IUploadLinkResponse): UploadLink {
        return new UploadLink(
            response.uid,
            response.url
        );
    }
}
