import { useTranslation } from 'react-i18next';
import 'app/common/presentation/component/popUpManager/createFirstPostModal/CreateFirstPostModal.scss';
import { CREATE_FIRST_POST_MODAL_INSTRUCTIONS,CREATE_FIRST_POST_MODAL_TITLE,NEW_POST,LATER } from 'translations/tr';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ShowCreateFirstPostModalActions } from 'app/common/presentation/redux/createPostModal/ShowCreateFirstPostModalSlice';
import { ESexonButtonStyle } from 'app/common/presentation/component/button/ESexonButtonStyle';
import { SexonModal } from 'app/common/presentation/component/modal/SexonModal';
import { SexonButton } from 'app/common/presentation/component/button/SexonButton';
import { useShowCreateFirstPostModalReduxState } from 'app/common/presentation/redux/createPostModal/hook/useShowCreateFirstPostModalReduxState';
import { ShowCreatePostModalActions } from 'app/common/presentation/redux/createPostModal/ShowCreatePostModalSlice';
import { useCurrentProfile } from '../../../hook/useCurrentProfile';

export const CreateFirstPostModal = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentProfile } = useCurrentProfile();
    const showModal = useShowCreateFirstPostModalReduxState();
    const { t } = useTranslation();
    const helpCenterUrl = process.env.REACT_APP_HELP_CENTER_URL;
    const goToProfile = (): void => {
        navigate(`/${currentProfile.userName}/`);
    };

    return (
        <SexonModal
            show={ showModal }
            classname="component-create-first-post-modal"
            headerTitle={ t(CREATE_FIRST_POST_MODAL_TITLE) }
            body={ (
                <div className="create-first-post-modal-body-container">
                    <p
                        dangerouslySetInnerHTML={ { __html: t(CREATE_FIRST_POST_MODAL_INSTRUCTIONS , { helpCenterUrl }).toString() } }
                    />
                </div>
            ) }

            footer={(
                <div className="create-first-post-modal-footer-container">
                    <SexonButton
                        title={ t(LATER).toUpperCase()}
                        type="button"
                        styling={ESexonButtonStyle.BASIC_PRIMARY}
                        onClick={(): void=> {
                            goToProfile();
                            dispatch(ShowCreateFirstPostModalActions.handleHideCreateFirstPostModal());
                        }}
                    />
                    <SexonButton
                        title={ t(NEW_POST).toUpperCase()}
                        type="button"
                        styling={ESexonButtonStyle.FILLED}
                        onClick={(): void=> {
                            dispatch(ShowCreatePostModalActions.handleShowCreatePostModal());
                            dispatch(ShowCreateFirstPostModalActions.handleHideCreateFirstPostModal());
                        }}
                    />
                </div>
            )}
        />
    );
};
