import React,{ ReactElement } from 'react';
import { CameraIcon,VideoIcon } from 'assets/icon/sexon/svg/icons';
import {
    IMAGE_POST_SKELETON_SUBTITLE,
    IMAGE_POST_SKELETON_TITLE,
    VIDEO_POST_SKELETON_SUBTITLE,
    VIDEO_POST_SKELETON_TITLE
} from 'translations/tr';
import { EPostTypeValue,PostType } from 'app/module/profile/domain/valueObject/PostType';
import { useTranslation } from 'react-i18next';
import './PostSkeleton.scss';

interface ISkeletonData{
    title: string
    subtitle: string
    icon: ReactElement
}

interface IPostSkeletonProps{
    type: PostType
    onClick: () => void
}

export const PostSkeleton = (props: IPostSkeletonProps): ReactElement => {
    const { t } = useTranslation();

    const skeletons: {
        [key in EPostTypeValue]: ISkeletonData
    } = {
        [EPostTypeValue.IMAGE]: {
            title: t(IMAGE_POST_SKELETON_TITLE),
            subtitle: t(IMAGE_POST_SKELETON_SUBTITLE),
            icon: <CameraIcon className="post-skeleton-icon" />,
        },
        [EPostTypeValue.VIDEO]: {
            title: t(VIDEO_POST_SKELETON_TITLE),
            subtitle: t(VIDEO_POST_SKELETON_SUBTITLE),
            icon: <VideoIcon className="post-skeleton-icon" />,
        },
    };

    const skeletonData = skeletons[props.type.value];

    return (
        <div
            onClick={props.onClick}
            className="component-post-skeleton"
        >
            {skeletonData.icon}
            <h2 className="post-skeleton-title">{skeletonData.title}</h2>
            <p className="post-skeleton-subtitle">{skeletonData.subtitle}</p>
        </div>
    );
};
