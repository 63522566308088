import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ICreatePostFormData } from 'app/common/presentation/component/popUpManager/createPostModal/form/CreatePostFormData';
import { PostImageFormField } from 'app/common/presentation/component/popUpManager/createPostModal/form/field/PostImageFormField';
import { PostDescriptionFormField } from 'app/common/presentation/component/popUpManager/createPostModal/form/field/PostDescriptionFormField';
import 'app/common/presentation/component/popUpManager/createPostModal/form/CreatePostForm.scss';
import { PostTypeFormField } from 'app/common/presentation/component/popUpManager/createPostModal/form/field/PostTypeFormField';
import { EPostTypeValue } from 'app/module/profile/domain/valueObject/PostType';
import { PostVideoFormField } from 'app/common/presentation/component/popUpManager/createPostModal/form/field/PostVideoFormField';
import { useCreatePostFormController } from 'app/common/presentation/component/popUpManager/createPostModal/form/useCreatePostFormController';

interface ICreatePostFormProps {
    createPostUseForm: UseFormReturn<ICreatePostFormData, any>
}

export const CreatePostForm = (props: ICreatePostFormProps): JSX.Element => {
    const controller = useCreatePostFormController({
        createPostUseForm: props.createPostUseForm
    });

    return (
        <form className="create-post-form-container">
            <PostTypeFormField
                onClick={controller.handlePostType}
                watchType={controller.watchPostType}
            />
            {
                controller.watchPostType === EPostTypeValue.IMAGE ? (
                    <PostImageFormField
                        showError={!!controller.postImageError}
                        onChange={controller.onChangeImageField}
                        watchImage={controller.watchPostImage.value}
                        errorMessage={controller.postImageError?.message || ''}
                    />
                ) : (
                    <PostVideoFormField
                        showError={!!controller.postVideoError}
                        onChange={controller.onChangeVideoField}
                        errorMessage={controller.postVideoError?.message || ''}
                    />
                )
            }
            <PostDescriptionFormField
                register={controller.postDescriptionRegister}
                showError={!!controller.postDescriptionError}
                watchRegister={controller.watchPostDescription}
                errorMessage={controller.postDescriptionError?.message ?? ''}
            />
        </form>
    );
};
