import IUploadRepository from 'app/module/profile/domain/interface/UploadRepository';
import { IAuth } from 'app/module/auth/domain/entity/Auth';
import { ISexonApiClient } from 'app/common/data/remote/sexonApi/AxiosSexonApiClient';
import { IUploadedImageResponse } from 'app/common/data/remote/sexonApi/dto/response/UploadedImageResponse';
import { IUploadedImage } from 'app/module/profile/domain/entity/UploadedImage';
import { UploadLink } from 'app/module/profile/domain/entity/UploadLink';
import { UploadLinkDataMapper } from 'app/module/profile/data/repository/api/upload/dataMapper/UploadLinkDataMapper';
import { CreateUploadLinkRequestDataMapper } from 'app/module/profile/data/repository/api/upload/dataMapper/CreateUploadLinkRequestDataMapper';

export class ApiUploadRepository implements IUploadRepository {

    public constructor(
        private _sexonApiClient: ISexonApiClient
    ) {}

    public async uploadImage(
        image: File,
        auth: IAuth,
    ): Promise<IUploadedImage> {
        try {
            const formData = new FormData();
            formData.append('file', image);
            const data: IUploadedImageResponse =  await this._sexonApiClient.uploadImage({
                file: formData,
                token: auth.token.value
            });

            return {
                name: data.field_name,
                url: data.url,
            };
        } catch (error){
            return Promise.reject(error);
        }
    }

    public async createUploadLink(
        startTime: number,
        endTime: number,
        duration: number,
        size: number,
        auth: IAuth
    ): Promise<UploadLink> {
        try {
            const data =  await this._sexonApiClient.createUploadLink(
                CreateUploadLinkRequestDataMapper.fromDomain(
                    startTime,
                    endTime,
                    duration,
                    size,
                    auth
                )
            );

            return UploadLinkDataMapper.fromResponse(data);
        } catch (error){
            return Promise.reject(error);
        }
    }
}
